import React, { useEffect } from 'react';
import { TextSmall } from '../../components/atoms/Typography/Typography';
import databseListImg from '../../assets/images/mockup_database.png';
import checkedImg from '../../assets/images/iconCheck.svg';
import {
  Content,
  Description,
  List,
  Subtitle,
  ListItem,
  Wrapper,
  ImageWrapper,
  TextBlock,
} from './styled';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

export const Engineering = () => {
  const controls = useAnimation();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Wrapper>
      <Content
        animate={controls}
        contentNumber={2}
        initial="hidden"
        ref={ref}
        transition={{ duration: 0.3, delay: 0.5 }}
        variants={variants}
      >
        <TextBlock>
          <Subtitle
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 1.5 }}
            variants={variants}
          >
            Predictive <br />
            Antibody Engineering
          </Subtitle>
          <Description
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 2 }}
            variants={variants}
          >
            Accelerate your antibody engineering project and streamline your
            decision-making process. Characterize your antibodies using
            predictive tools covering a wide range of structural, sequence, and
            developability prediction applications.
          </Description>
          <List
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 2.5 }}
            variants={variants}
          >
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Annotate individual and multiple sequences with
                statistical/machine learning scores.
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Annotate developability-risk contribution
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Annotate naturally-observed diversity
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">Structure predictions</TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">Surface annotation</TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Positional AA predictions, based on masked language models
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Get a custom solution specific to your case
              </TextSmall>
            </ListItem>
          </List>
        </TextBlock>
        <ImageWrapper>
          <img alt="Antibody Engineering" src={databseListImg} />
        </ImageWrapper>
      </Content>
    </Wrapper>
  );
};
