import React, { useCallback, useEffect } from 'react';
import Logo from '../../components/atoms/NavLogoWrapper';
import styled, { css } from 'styled-components';
import { ExternalLink } from '../../components/atoms/Link';
import { useState } from 'react';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { motion } from 'framer-motion';
import { calendlyLink } from '../../constants';

export const TopNavigation = () => {
  const [scroll, setScroll] = useState(0);

  const breakpoint = useMaxWidthBreakpoint(800);

  const scrollCallback = useCallback(() => {
    setScroll(window.scrollY);
  }, [scroll]);

  useEffect(() => {
    window.addEventListener('scroll', scrollCallback);

    return () => {
      window.removeEventListener('scroll', scrollCallback);
    };
  }, [scrollCallback]);

  const changeColor = scroll > 810 && scroll < 4020 ? true : false;

  return (
    <Wrapper isScrolled={changeColor}>
      <Logo isScrolled={!changeColor} onlyLogo={breakpoint} open={true} />
      <WrapperLinks>
        <Button
          $color="delicateStronger"
          href="https://abstudio.naturalantibody.com/login"
        >
          Log in
        </Button>
        <ExternalButton href={calendlyLink}> Book a demo</ExternalButton>
      </WrapperLinks>
    </Wrapper>
  );
};

const Wrapper = styled.nav<{ isScrolled: boolean }>`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      ${ExternalLink} {
        color: #fff;
      }

      ${ExternalButton} {
        background: ${({ theme }) =>
          theme.colors.accents.secondaryAccentedTransparent};

        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.backgrounds.primaryAccented};
        }
      }
    `}
`;

const Button = styled(ExternalLink)`
  margin-right: 1rem;

  &:hover {
    box-shadow: none;
    cursor: pointer;
  }
`;

const WrapperLinks = styled.div``;

const ExternalButton = styled(motion.a)`
  margin-top: 2rem;
  font-size: 14px;
  padding: 16px 28px;
  color: ${({ theme }) => theme.colors.texts.primaryAccented};
  background: ${({ theme }) => theme.colors.backgrounds.buttonSecondary};

  border-radius: 51px;
  text-decoration: none;
  transition: 0.2s background;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.accents.secondary};
  }
`;
