import React, { useEffect } from 'react';
import { TextSmall } from '../../components/atoms/Typography/Typography';
import databseListImg from '../../assets/images/mockup_analytics.png';
import checkedImg from '../../assets/images/iconCheck.svg';
import {
  Content,
  Description,
  List,
  Subtitle,
  ListItem,
  Wrapper,
  ImageWrapper,
  TextBlock,
  Badge,
} from './styled';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Analytics = () => {
  const controls = useAnimation();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Wrapper>
      <Content
        animate={controls}
        contentNumber={3}
        initial="hidden"
        ref={ref}
        transition={{ duration: 0.3, delay: 0.5 }}
        variants={variants}
      >
        <ImageWrapper>
          <img alt="Antibody Analytics" src={databseListImg} />
        </ImageWrapper>
        <TextBlock>
          <Badge
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 1.5 }}
            variants={variants}
          >
            <TextSmall $color="secondary" $uppercase>
              {' '}
              Coming soon
            </TextSmall>
          </Badge>
          <Subtitle
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 1.5 }}
            variants={variants}
          >
            Antibody Analytics
          </Subtitle>
          <Description
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 2 }}
            variants={variants}
          >
            Facilitate research decision-making, discover the advantages of
            candidate antibodies, stratify outputs of Next-Generation Sequencing
            (NGS) or phage display, and accelerate your research.
          </Description>
          <List
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, delay: 2.5 }}
            variants={variants}
          >
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">Molecule-level annotations</TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Aggregation, binding, and solubility prediction (using your
                experimental data)
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Sequence/structure-level annotations
              </TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">Surface immunogenicity</TextSmall>
            </ListItem>
            <ListItem>
              <img alt="checked" src={checkedImg} />
              <TextSmall $color="primary">
                Extended liabilities with natural diversity
              </TextSmall>
            </ListItem>
          </List>
        </TextBlock>
      </Content>
    </Wrapper>
  );
};
