import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  TextLarge,
  GradientHeader,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
} from '../../styles/mediaQueries';
import circle from '../../assets/images/share/projects_circleBg.svg';
import client_1 from '../../assets/images/share/user/1.jpg';
import client_2 from '../../assets/images/share/user/2.png';
import client_3 from '../../assets/images/share/user/3.png';
import client_4 from '../../assets/images/share/user/4.png';
import client_5 from '../../assets/images/share/user/5.png';
import client_6 from '../../assets/images/share/user/6.png';
import projects from '../../assets/images/share/mockup_projects.png';
import text1 from '../../assets/images/share/text_1.png';
import text2 from '../../assets/images/share/text_2.png';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Share = () => {
  const controls = useAnimation();

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Wrapper>
      <SectionTitle
        animate={controls}
        initial="hidden"
        ref={ref}
        transition={{ duration: 0.3, delay: 0.5 }}
        variants={variants}
      >
        Share the results <br /> with your team
      </SectionTitle>
      <Description
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.3, delay: 1 }}
        variants={variants}
      >
        Easily save and sort research results into projects. You can add an
        individual result or the entire list with one click. Share the organized
        results with your coworkers with annotations for extra context. Add
        users and manage access using specified roles for extra transparency and
        security.
      </Description>
      <ProjectsWrapper>
        <motion.img
          alt="Projects Page"
          animate={controls}
          initial="hidden"
          src={projects}
          transition={{ duration: 0.3, delay: 1.5 }}
          variants={variants}
        />
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 2 }}
          variants={variants}
        >
          <img alt="client 1" src={client_1} />
        </Participant>
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 2.5 }}
          variants={variants}
        >
          <img alt="client 2" src={client_2} />
        </Participant>
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 3 }}
          variants={variants}
        >
          <img alt="client 3" src={client_3} />
        </Participant>
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 3.5 }}
          variants={variants}
        >
          <img alt="client 4" src={client_4} />
        </Participant>
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 4 }}
          variants={variants}
        >
          <img alt="client 5" src={client_5} />
        </Participant>
        <Participant
          animate={controls}
          initial="hidden"
          transition={{ duration: 0.3, delay: 4.5 }}
          variants={variants}
        >
          <img alt="client 6" src={client_6} />
        </Participant>
      </ProjectsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 108rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 3rem auto 20rem auto;
  z-index: 1;
  overflow-x: hidden;

  &:before {
    content: ' ';
    position: absolute;
    width: 916px;
    height: 916px;
    z-index: -1;
    top: 7%;
    left: 50%;
    transform: translateX(-50%);
    background: url(${circle}) no-repeat;
  }
`;

const SectionTitle = styled(motion(GradientHeader)).attrs({
  $gradient: 'primary',
  $color: 'delicate',
})`
  margin: 7px 0 25px;
  font-size: 48px;
  text-align: center;

  color: ${({ theme }) => theme.colors.texts.delicate};
  background: linear-gradient(261.06deg, #93b5b1 43.2%, #457771 83.48%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  p {
    white-space: nowrap;

    ${forScreenAtMost800pxWide(css`
      font-size: 38px;
    `)}

    ${forScreenAtMost680pxWide(css`
      font-size: 32px;
      white-space: normal;
      text-align: center;
    `)}
  }
`;

const Description = styled(motion(TextLarge)).attrs({ $color: 'secondary' })`
  width: 50%;
  text-align: center;
  font-size: 16px;

  color: ${({ theme }) => theme.colors.texts.secondary};

  ${forScreenAtMost800pxWide(css`
    font-size: 14px;
    width: 90%;
  `)}
`;

const ProjectsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60rem;
  overflow-x: hidden;

  img {
    height: min-content;
    position: absolute;
    top: 20%;

    ${forScreenAtMost800pxWide(css`
      top: 15%;
      width: 50%;
    `)}

    ${forScreenAtMost680pxWide(css`
      width: 90%;
    `)}
  }
`;

const Participant = styled(motion.div)`
  position: absolute;
  width: 4rem;
  img {
    border-radius: 5rem;
    width: 5rem;
    height: auto;
  }

  &:nth-of-type(1) {
    left: 8%;
    top: 50%;
    img {
      width: 4rem;
    }

    ${forScreenAtMost800pxWide(css`
      left: 13%;
      top: 30%;
    `)}

    ${forScreenAtMost680pxWide(css`
      left: 16%;
      top: 38%;

      img {
        width: 5rem;
      }

      &:after {
        display: none;
      }
    `)}

    &:after {
      content: '';
      background: url(${text1}) no-repeat;
      background-size: 20rem;
      position: absolute;
      width: 20rem;
      left: -3px;
      top: -62px;
      height: 5rem;
    }
  }

  &:nth-of-type(2) {
    left: 58%;
    top: 59%;

    img {
      width: 5rem;
    }

    ${forScreenAtMost800pxWide(css`
      top: 57%;
      img {
        width: 4rem;
      }
    `)}

    ${forScreenAtMost680pxWide(css`
      left: 50%;
      top: 48%;

      &:after {
        display: none;
      }
    `)}

    &:after {
      content: '';
      background: url(${text2}) no-repeat;
      background-size: 20rem;
      position: absolute;
      width: 20rem;
      left: -9px;
      top: -62px;
      height: 5rem;
    }
  }
  &:nth-of-type(3) {
    left: 34%;
    top: 55%;
    img {
      width: 4rem;
    }

    ${forScreenAtMost800pxWide(css`
      left: 34%;
      top: 51%;
    `)}

    ${forScreenAtMost680pxWide(css`
      display: none;
    `)}
  }
  &:nth-of-type(4) {
    left: 68%;
    top: 3%;
    img {
      width: 4rem;
    }

    ${forScreenAtMost800pxWide(css`
      left: 70%;
      top: 8%;
      img {
        width: 5rem;
      }
    `)}

    ${forScreenAtMost680pxWide(css`
      left: 63%;
      top: 10%;
    `)}
  }
  &:nth-of-type(5) {
    left: 24%;
    top: 9%;
    img {
      width: 6rem;
    }

    ${forScreenAtMost800pxWide(css`
      left: 24%;
      top: 9%;
      img {
        width: 4rem;
      }
    `)}

    ${forScreenAtMost680pxWide(css`
      left: 18%;
      top: 4%;
    `)}
  }
  &:nth-of-type(6) {
    left: 80%;
    top: 24%;

    img {
      width: 4rem;
    }

    ${forScreenAtMost680pxWide(css`
      display: none;
    `)}
  }
`;
