import React, { useEffect } from 'react';
import { TextSmall } from '../../components/atoms/Typography/Typography';
import databseListImg from '../../assets/images/mockup_database.png';
import checkedImg from '../../assets/images/iconCheck.svg';
import ResearchResultsImg from '../../assets/images/mockup_rozkladacz_full.png';
import { useViewportScroll, useTransform, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  Content,
  Description,
  List,
  Subtitle,
  ListItem,
  Title,
  Wrapper,
  ImageWrapper,
  TextBlock,
  AnimationWrapper,
  WrappperAnimation,
} from './styled';

export const Database = () => {
  const { scrollYProgress } = useViewportScroll();
  const controls = useAnimation();
  const controlsContent = useAnimation();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  const yPosAnim = useTransform(
    scrollYProgress,
    [0, 0.1, 1000],
    [0, 250, 1000],
  );

  const rotate = useTransform(scrollYProgress, [0, 0.1, 100], [60, 0, 0]);
  const perspective = useTransform(
    scrollYProgress,
    [0, 5, 100],
    [1000, 50, -1000],
  );

  const position = useTransform(scrollYProgress, [0, 0.1, 100], [1, 10, 100]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { ref: refContent, inView: inViewContent } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }

    if (inViewContent) {
      controlsContent.start('visible');
    }
  }, [controls, inView, inViewContent]);

  return (
    <>
      <WrappperAnimation
        style={{
          perspective: perspective,
        }}
      >
        <AnimationWrapper
          style={{
            rotateX: rotate,
            y: yPosAnim,
          }}
        >
          <img alt="Research results" src={ResearchResultsImg} />
        </AnimationWrapper>
      </WrappperAnimation>

      <Wrapper>
        <Title
          animate={controls}
          initial="hidden"
          ref={ref}
          transition={{ duration: 0.3, delay: 0.5 }}
          variants={variants}
        >
          Explore <br />
          our features
        </Title>
        <Content
          animate={controlsContent}
          contentNumber={1}
          initial="hidden"
          ref={refContent}
          transition={{ duration: 0.3, delay: 0.7 }}
          variants={variants}
        >
          <ImageWrapper
            style={{
              y: position,
            }}
          >
            <img alt="Antibody Database" src={databseListImg} />
          </ImageWrapper>
          <TextBlock>
            <Subtitle
              animate={controlsContent}
              initial="hidden"
              transition={{ duration: 0.3, delay: 1 }}
              variants={variants}
            >
              Interactive <br />
              Antibody Database
            </Subtitle>
            <Description
              animate={controlsContent}
              initial="hidden"
              transition={{ duration: 0.3, delay: 1.4 }}
              variants={variants}
            >
              Save time in creating and collating antibody-specific datasets.
              Benefit from ready-made data collection pipelines that have fresh
              and regularly updated datasets. Search for antibodies by variable
              region sequence, cdrH3 sequence or text and get all the search
              results in one place. Compare parameters and find shared
              attributes across different databases (patents, scientific
              literature, NGS, GenBank, and others).
            </Description>
            <List
              animate={controlsContent}
              initial="hidden"
              transition={{ duration: 0.3, delay: 1.8 }}
              variants={variants}
            >
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Check if a sequence was observed in any of the heterogeneous
                  databases
                </TextSmall>
              </ListItem>
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Find a similar sequence with legal protection
                </TextSmall>
              </ListItem>
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Identify sequences associated with specific keywords
                </TextSmall>
              </ListItem>
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Get target/disease context for a specific sequence
                </TextSmall>
              </ListItem>
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Explore the mutational diversity and the disease/vaccine
                  context of sequences similar to yours
                </TextSmall>
              </ListItem>
              <ListItem>
                <img alt="checked" src={checkedImg} />
                <TextSmall $color="primary">
                  Get a custom solution specific to your case
                </TextSmall>
              </ListItem>
            </List>
          </TextBlock>
        </Content>
      </Wrapper>
    </>
  );
};
