import React from 'react';
import styled, { css } from 'styled-components';
import {
  Title,
  TextSmall,
  TextLarge,
} from '../../components/atoms/Typography/Typography';
import circle from '../../assets/images/hero_circle_bg.svg';
import antiverseLogo from '../../assets/images/trusted-us/black/antiverse.svg';
import astraZenecaLogo from '../../assets/images/trusted-us/black/astrazeneca.svg';
import gv20Logo from '../../assets/images/trusted-us/black/gv20.svg';
import alchemabLogo from '../../assets/images/trusted-us/black/alchemab.svg';
import {
  forScreenAtMost800pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import { motion } from 'framer-motion';
import { ExternalLink } from '../../components/atoms/Link';
import { calendlyLink } from '../../constants';

export const IntroSection = () => {
  return (
    <Wrapper>
      <SectionTitle
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3, delay: 0.5 },
        }}
        initial={{ y: 10, opacity: 0 }}
      >
        <span>Advanced data-driven</span>{' '}
        <span>antibody analytics and discovery</span>
      </SectionTitle>
      <Description
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3, delay: 0.55 },
        }}
        initial={{ y: 10, opacity: 0 }}
      >
        Create, collate, and discover antibody-specific <br />
        datasets to accelerate research decision-making.
      </Description>
      <ExternalButton
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3, delay: 0.6 },
        }}
        href={calendlyLink}
        initial={{ y: 10, opacity: 0 }}
      >
        Book a demo
      </ExternalButton>
      <TrustedUsText
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3, delay: 1.3 },
        }}
        initial={{ y: 10, opacity: 0 }}
      >
        Trusted us
      </TrustedUsText>
      <WrapperPartners
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3, delay: 1.3 },
        }}
        initial={{ y: 10, opacity: 0 }}
      >
        <li>
          <StyledExternalLink href="https://www.antiverse.io">
            <img height="24" src={antiverseLogo} width="111" />
          </StyledExternalLink>
        </li>
        <li>
          <StyledExternalLink href="https://www.astrazeneca.com">
            <img height="33" src={astraZenecaLogo} width="132" />
          </StyledExternalLink>
        </li>
        <li style={{ position: 'relative', top: 5 }}>
          <StyledExternalLink href="http://gv20oncotherapy.com">
            <img height="39" src={gv20Logo} width="87" />
          </StyledExternalLink>
        </li>
        <li style={{ position: 'relative', top: 5 }}>
          <StyledExternalLink href="https://www.alchemab.com">
            <img height="45" src={alchemabLogo} width="101" />
          </StyledExternalLink>
        </li>
      </WrapperPartners>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8rem auto 20rem auto;
  position: relative;
  z-index: 1;

  width: 100%;
  max-width: 1440px;

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -65%);
    width: 100%;
    height: 1181px;

    background: url(${circle}) no-repeat center;
    background-size: 100%;

    ${forScreenAtMost800pxWide(css`
      transform: translate(-50%, -40%);
    `)}
  }
`;

const SectionTitle = styled(motion(Title)).attrs({ $color: 'primary' })`
  margin: 7px 0 25px;
  font-size: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    white-space: nowrap;

    ${forScreenAtMost800pxWide(css`
      font-size: 38px;
      max-width: 90%;
    `)}

    ${forScreenAtMost680pxWide(css`
      font-size: 32px;
      white-space: normal;
      text-align: center;
    `)}

    color: ${({ theme }) => theme.colors.texts.tertiary};
    &:first-of-type {
      color: ${({ theme }) => theme.colors.texts.delicate};
    }
  }
`;

const Description = styled(motion(TextLarge)).attrs({
  $color: 'delicateAccented',
})`
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.texts.delicateAccented};

  ${forScreenAtMost800pxWide(css`
    font-size: 14px;
  `)}
`;

const WrapperPartners = styled(motion.ul)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  margin-top: 2rem;
  list-style-type: none;
  width: 100%;
  height: 100%;
  z-index: 5;

  ${forScreenAtMost800pxWide(css`
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 3rem;
    padding-left: 18rem;
    padding-right: 1rem;
    li {
      flex: 0 0 10%;
    }
  `)}

  &:-webkit-scrollbar {
    display: none;
  }
`;

const StyledExternalLink = styled(motion(ExternalLink)).attrs({
  $focusBg: 'focus',
})`
  opacity: 0.5;

  transition: opacity 0.1s ease-in-out;

  :hover,
  :focus {
    opacity: 1;
  }

  display: inline-block;
  width: 100%;
  height: 100%;
`;

const TrustedUsText = styled(motion(TextSmall)).attrs({
  $color: 'secondary',
})`
  margin-top: 90px;
  color: ${({ theme }) => theme.colors.texts.secondary};
  text-transform: uppercase;
`;

const ExternalButton = styled(motion.a)`
  margin-top: 2rem;
  font-size: 14px;
  padding: 16px 28px;
  color: ${({ theme }) => theme.colors.texts.primaryAccented};
  background: ${({ theme }) => theme.colors.backgrounds.buttonSecondary};

  border-radius: 51px;
  text-decoration: none;
  transition: 0.2s background;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.accents.secondary};
  }
`;
