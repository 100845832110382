import styled, { css } from 'styled-components';
import {
  GradientTitle,
  TextLarge,
  TextSmall,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost800pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import circle from '../../assets/images/circle_fill.svg';
import circleDark from '../../assets/images/circle_fill_dark.svg';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    width: 50%;
    background-color: ${({ theme }) => theme.colors.backgrounds.button};
    height: 10px;
    bottom: 0px;
    right: 0;
  }

  &:nth-child(4) {
    padding: 40rem 0 8rem;
    background-color: #103f46;
    &:after {
      background-color: #154c54;
    }
  }

  &:nth-child(5) {
    height: 800px;
    background-color: #154c54;

    &:after {
      background-color: #1b565f;
    }
  }

  &:nth-child(6) {
    height: 800px;
    background-color: #1b565f;
    &:after {
      background-color: #fff;
      bottom: 0;
      left: 0;
    }
  }
`;

export const Title = styled(motion(GradientTitle)).attrs({
  $gradient: 'primary',
  $color: 'delicate',
})`
  font-size: 48px;
  margin-top: 10rem;
  text-align: center;
  margin-bottom: 10rem;
  color: ${({ theme }) => theme.colors.texts.delicate};
  background: linear-gradient(261.06deg, #93b5b1 43.2%, #457771 83.48%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  ${forScreenAtMost800pxWide(css`
    font-size: 38px;
    margin-top: 1rem;
    margin-bottom: 3rem;
  `)}

  ${forScreenAtMost680pxWide(css`
    font-size: 32px;
  `)}
`;

export const Content = styled(motion.div)<{ contentNumber: number }>`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  position: relative;
  padding: 0 3rem;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  ${forScreenAtMost800pxWide(css`
    flex-direction: column;
    margin-top: 2rem;
  `)}

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -55%;
    left: 25%;
    width: 100%;
    height: 1181px;

    background: url(${circle}) no-repeat center;
    background-size: 50%;

    ${({ contentNumber }) => {
      switch (contentNumber) {
        case 1:
          return css`
            background: url(${circleDark}) no-repeat center;
            background-size: 70%;
            top: -70%;
            left: -33%;

            ${forScreenAtMost680pxWide(css`
              background-size: 100%;
              top: -45%;
            `)}
          `;
        case 2:
          return css`
            background-size: 60%;
            top: -55%;
            left: 35%;

            ${forScreenAtMost800pxWide(css`
              background-size: 100%;
              top: -45%;
            `)}
          `;
        case 3:
          return css`
            background-size: 60%;
            top: -50%;
            left: -25%;
            opacity: 0.7;

            ${forScreenAtMost800pxWide(css`
              background-size: 100%;
              top: -55%;
              left: -35%;
            `)}
          `;
      }
    }}
  }
`;

export const Subtitle = styled(motion(TextLarge)).attrs({ $color: 'primary' })`
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.texts.primary};
`;

export const Description = styled(motion(TextSmall)).attrs({
  $color: 'delicate',
})`
  max-width: 400px;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.texts.delicate};
`;

export const List = styled(motion.ul)`
  list-style: none;
  padding-left: 0;
`;

export const ListItem = styled(motion.li)`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const ImageWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  height: min-content;
  position: relative;

  ${forScreenAtMost800pxWide(css`
    order: -1;
  `)}

  img {
    width: 90%;

    ${forScreenAtMost800pxWide(css`
      width: 125%;
    `)}
  }
`;

export const TextBlock = styled.div`
  margin-top: 2rem;
`;

export const Badge = styled(motion.div)`
  padding: 0.4rem 1rem;
  background-color: ${({ theme }) => theme.colors.accents.primary};
  width: min-content;
  white-space: nowrap;
  border-radius: 20px;
  margin-bottom: 0.5rem;

  p {
    letter-spacing: 1px;
  }
`;

export const AnimationWrapper = styled(motion.div)`
  max-width: 85%;
`;

export const WrappperAnimation = styled(motion.div)`
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 3;
  top: 27rem;
  display: flex;
  justify-content: center;

  ${forScreenAtMost800pxWide(css`
    top: 35rem;
  `)}

  img {
    width: 100%;
    max-width: 1000px;
  }
`;
