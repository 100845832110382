import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import {
  IntroSection,
  Database,
  Engineering,
  Analytics,
  Share,
  TopNavigation,
} from '../pages-components/abstudio';

const Landing = () => {
  return (
    <Layout
      backgroundColor="secondary"
      description="Discover antibody-based drug candidates faster and accelerate your research decision-making using Antibody Discovery Platform, an AI-powered antibody discovery solution."
      footerBoxContent={FooterBoxContent.BookDemo}
      title="AbStudio - AI-Driven Antibody Discovery and Analytics | NaturalAntibody"
      topNavigation={<TopNavigation />}
    >
      <IntroSection />
      <Database />
      <Engineering />
      <Analytics />
      <Share />
    </Layout>
  );
};

export default Landing;
